
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch, onBeforeMount } from 'vue';
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampEmptyListFeedbackV2
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { useFilterMainStore } from '@/store/FilterMainStore';
import { useCompanyStore } from '@/store/CompanyStore';
import { useStoreStore } from '@/store/StoreStore';
import { useSaleStore } from '@/store/SaleStore';
// import ModalExcellURLList from "./Components/ModalExcellURLList.vue";
import moment from 'moment';
import { campHandleCPF } from '@/composables/DataValidation';
import { useSocketStore } from '@/store/SocketStore';
import { useAuthStore } from '@/store/AuthStore';

interface ISaleDataFetch {
  id: number;
  cpf: string;
  name: string;
  product: string;
  status: string | number;
  quantity: number;
  current_points: 2
  date: string
}

interface ISalePrint extends ISaleDataFetch {
  checked: boolean;
  value: number;
}

interface ISaleResponse {
  data: {
    data: ISaleDataFetch[]
    error: boolean,
    message: string,
    errorCode: string
  }
}

interface ISaleListToPost {
  rescue_id: number,
  cpf: string,
  name: string,
  pdv: string,
  points: number,
  value: string
}

export interface IExcellURL { 
  url: string
}

export default defineComponent({
  name: "SaleList",
  components: {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    // ModalExcellURLList,
    CampEmptyListFeedbackV2
  },
  setup() {
    const { showTimeAlert, showTimeConfirmAction } = useAlert()
    const loaderStore = useLoaderStore()
    const filterMainStore = useFilterMainStore()
    const authStore = useAuthStore()
    // Company
    const companyStore = useCompanyStore()
    // Store
    const storeStore = useStoreStore()
    // Sale
    const areAllSelected = ref(false)
    const isLoading = ref(false)
    const saleStore = useSaleStore()
    const saleList = ref<ISalePrint[] | null>(null)
    const saleListRender = ref<ISalePrint[] | null>(null)
    const keywordSearch = ref("")
    const saleListToPost = ref<number[] | null>(null)
    const toggleModalExcellURLList = ref(false)
    const socketStore = useSocketStore()

    // Functions
    /* Fetch */
    async function getSaleList(isLoadingProp = true) {
      if(!companyStore.getId) {
        return true
      }
      areAllSelected.value = false
      keywordSearch.value = ""
      try {
        isLoading.value = isLoadingProp
        let url = `/api/getSaleUnaproved`
        let params = "?"
        // if(storeStore.getId) {
        //   params += `id_store=${storeStore.getId}&`
        // }

        if(saleStore.getStartDate && saleStore.getEndDate) {
          params += `start_date=${moment(saleStore.getStartDate).format('DD-MM-YYYY')}&end_date=${moment(saleStore.getEndDate).format('DD-MM-YYYY')}&`
        }
        
        if(params !== "?") {
          url += params.slice(0, -1)
        }
        const response:ISaleResponse = await axios.get(url)
        const { data } = response.data
        saleList.value = data.map(el => ({ ...el, value: el.quantity, checked: false }))
        saleListRender.value = [ ...saleList.value ]
      } catch (error) {
        if(error instanceof AxiosError) {
          const isLevel600 = (authStore?.getUser?.role?.level >= 600) ? true : false
          if(isLevel600 && error.response?.data.message == "Necessário estar com uma loja selecionada!")
            showTimeAlert(error.response?.data.message, "warning")
          if(error.response?.data.message != "Necessário estar com uma loja selecionada!")
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
        isLoading.value = false
      }
      return false
    }

    async function updateSaleAproved(): Promise<boolean> {
      if(!saleListRender.value || !companyStore.getId) {
        return true
      }
      saleListToPost.value = saleListRender.value.filter((el: ISalePrint) => el.checked).map((el: ISalePrint) => el.id)
      if (!saleListToPost.value || Array.isArray(saleListToPost.value) && !saleListToPost.value.length) {
        showTimeAlert("Selecione pelo menos um registro na tabela para aprovar!", "error")
        return true
      }
      async function finishPost(param) {
        try {
          loaderStore.open()
          const result = await axios.post(`/api/updateSaleAproved`, [ ...param ])
          areAllSelected.value = false
          getSaleList()
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
        }
      }
      showTimeConfirmAction("Aprovar Selecionados", "Deseja confirmar?", "Sim", "Aprovado com sucesso!", () => {
        finishPost(saleListToPost.value)
      }, "success", "")
      return false
    }

    async function updateSaleDisapproved(): Promise<boolean> {
      if(!saleListRender.value || !companyStore.getId) {
        return true
      }
      saleListToPost.value = saleListRender.value.filter((el: ISalePrint) => el.checked).map((el: ISalePrint) => el.id)
      if (!saleListToPost.value || Array.isArray(saleListToPost.value) && !saleListToPost.value.length) {
        showTimeAlert("Selecione pelo menos um registro na tabela para aprovar!", "error")
        return true
      }
      async function finishPost(param) {
        try {
          loaderStore.open()
          const result = await axios.post(`/api/updateSaleDisapproved`, [ ...param ])
          areAllSelected.value = false
          getSaleList()
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
        }
      }
      showTimeConfirmAction("Reprovar Selecionados", "Deseja confirmar?", "Sim", "Reprovado com sucesso!", () => {
        finishPost(saleListToPost.value)
      }, "success", "")
      return false
    }

    function handleFilterObjectsByKeyword(): boolean {
      areAllSelected.value = false
      if(!saleList.value)
        return true
      saleListRender.value = saleList.value.map(el => ({ ...el, checked: false })).filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      return false
    }

    function handleAllCheckbox() {
      if(!saleList.value)
        return true
        saleListRender.value = areAllSelected.value ? saleList.value.map(el => ({ ...el, checked: true })) : saleList.value.map(el => ({ ...el, checked: false }))
      return false
    }

    function handleFormatInMonentaryValue(value: number) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    }

    /* Local Storage */
    function getDataLocalStorage() {
      // const resetCompany = ():void => {
      //   companyStore.setId(null)
      //   companyStore.setFantasyName("")
      // }
      // try {
      //   const cs = localStorage.getItem("cs") ? JSON.parse(localStorage.getItem("cs")!) : null
      //   if(cs && cs.i) {
      //     companyStore.setId(cs.i)
      //     companyStore.setFantasyName(cs.n)
      //   } else {
      //     resetCompany()
      //   }
      // } catch (error) {
      //   resetCompany()
      // }

      // const resetStore = (): void => {
      //   storeStore.setId(null)
      //   storeStore.setFantasyName("")
      // }
      // try {
      //   const ss = localStorage.getItem("ss") ? JSON.parse(localStorage.getItem("ss")!) : null
      //   if(ss && ss.i) {
      //     storeStore.setId(ss.i)
      //     storeStore.setFantasyName(ss.n)
      //   } else {
      //     resetStore()
      //   }
      // } catch (error) {
      //   resetStore()
      // }

      // try {
      //   const dr = localStorage.getItem("dateRangeSaleStore")
      //   if(dr) {
      //     saleStore.setDateRange(dr.split(","))
      //   } else {
      //     saleStore.setDateRange("")
      //   }
      // } catch (error) {
      //   saleStore.setDateRange("")
      // }
    }

    /* Handle Filter Modal */
    function handleInitFilterModal() {
      if(!storeStore.getId) {
        filterMainStore.hideModal()
        filterMainStore.showModal()
      }
    }

    function initSocket() {
      socketStore.echo?.channel(`user.${authStore.getUser.id}`).listen(".SendMessageUser", () => { 
        getSaleList(false)
      })
    }

    // Life Cycles
    onBeforeMount(() => getDataLocalStorage())

    onMounted(() => {
      getSaleList()
      if(authStore && authStore.getUser && authStore.getUser.role && authStore.getUser.role.level < 600)
        handleInitFilterModal()
      initSocket()
    })


    watch(() => { companyStore.company, storeStore.store, saleStore.getStartDate, saleStore.getEndDate }, () => getSaleList(), { deep: true })

    watch(() => areAllSelected.value, () => handleAllCheckbox())

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    return {
      areAllSelected,
      isLoading,
      keywordSearch,
      companyStore,
      saleList,
      saleListRender,
      handleFilterObjectsByKeyword,
      updateSaleAproved,
      updateSaleDisapproved,
      toggleModalExcellURLList,
      handleFormatInMonentaryValue,
      moment,
      saleStore,
      campHandleCPF,
      authStore
    }
  }
})
